<div
  class="grid gap-7 lg:grid-cols-7 sm:gap-6 mb-4"
  *ngIf="btnTitle == 'Actualizar Plan'"
>
  <div class="mb-4 lg:col-span-1">
    <div class="font-semibold mb-1">Habilitar Edición</div>
    <label class="inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        value=""
        class="sr-only peer"
        [(ngModel)]="canEdit"
      />
      <div
        class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"
      ></div>
      <span class="ms-3 text-sm font-medium text-gray-400">Activo</span>
    </label>
  </div>
  <div
    class="lg:col-span-6 bg-gray-50 p-4 rounded-lg font-semibold text-orange-300"
    *ngIf="!canEdit"
  >
    Estimado usuario, estas en modo visualización, para editar la informacion
    debes habilitar la edicion, en el control de la izquierda
  </div>
  <div
    class="lg:col-span-6 bg-green-100 p-4 rounded-lg text-green-500 font-semibold"
    *ngIf="canEdit"
  >
    Modo edición activado
  </div>
</div>

<div class="grid gap-4 lg:grid-cols-7 sm:gap-6 mb-4">
  <div
    class="form-plan-control"
    [ngClass]="{
      'lg:col-span-4': plan.version_change.length > 0,
      'lg:col-span-7': plan.version_change.length == 0
    }"
  >
    <div id="accordion-collapse" data-accordion="collapse">
      <h2>
        <button
          type="button"
          class="flex items-center
          justify-between
           w-full
           p-3
           font-medium
           rtl:text-right
           border
           border-b-0
           border-gray-200
           hover:bg-gray-100
           gap-3"
          (click)="mangeAccordion(0)"
          [ngClass]="{
            'text-blue-500': accordioncontroller[0] == true,
            'text-gray-500': accordioncontroller[0] == false
          }"
        >
          <span>Configuración General</span>
          <svg
            data-accordion-icon
            class="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
            *ngIf="accordioncontroller[0] == false"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
          <svg
            *ngIf="accordioncontroller[0] == true"
            data-accordion-icon
            class="w-3 h-3 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div *ngIf="accordioncontroller[0] == true">
        <div class="py-3">
          <div class="mb-4">
            <div class="font-semibold mb-1">Estatus</div>
            <label class="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                value=""
                class="sr-only peer"
                [(ngModel)]="plan.status"
                [disabled]="!canEdit"
              />
              <div
                class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"
              ></div>
              <span class="ms-3 text-sm font-medium text-gray-400">Activo</span>
            </label>

            <div class="mb-4">
              <div class="font-semibold mb-1">Multicompra</div>
              <label class="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  [(ngModel)]="plan.params.multiple_invoices.status"
                  [disabled]="!canEdit"
                  name="multiple_invoices.status"
                  id="multiple_invoices_flag"
                  class="sr-only peer"
                />
                <div
                  class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"
                ></div>
                <span class="ms-3 text-sm font-medium text-gray-400"
                  >Permite Multicompra</span
                >
              </label>
            </div>
            <div
              class="grid gap-4  sm:gap-6 mb-4"
              *ngIf="plan.params.multiple_invoices.status"
              [ngClass]="{
                'lg:grid-cols-2': plan.version_change.length > 0,
                'lg:grid-cols-4': plan.version_change.length == 0
              }"
            >
              <div class="">
                <div for="code" class="block mb-2 text-sm font-semibold">
                 Cantidad de Compras Permitidas
                </div>
                <input
                  type="text"
                  name="multiple_invoices_count"
                  id="multiple_invoices_count"
                  [(ngModel)]="plan.params.multiple_invoices.count"
                  [disabled]="!canEdit"
                  id="code"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Cantidad de Compras Permitidas"
                  required=""
                  (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
                />
              </div>

              <div class="">
                <label for="countries" class="block mb-2 text-sm font-semibold"
                  >Prioridad</label
                >


                <input
                type="text"
                name="priority"
                [(ngModel)]="plan.params.priority"
                [disabled]="!canEdit"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="Indique un número para la prioridad: 0, 1, 2, 3,.. "
                required=""
                [disabled]="!canEdit"
                (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
              />
                <p class="text-red-400" *ngIf="errorControls.errorPriority">
                  Indique Prioridad
                </p>
              </div>
            </div>
          </div>


          <div class="grid gap-4 lg:grid-cols-4 sm:gap-6 mb-4">
            <div class="">
              <div for="name" class="block mb-2 text-sm font-semibold">
                <span class="text-red-500">*</span> Nombre
              </div>
              <input
                type="text"
                name="name"
                [(ngModel)]="plan.name"
                id="code"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="Nombre del Plan"
                required=""
                [disabled]="!canEdit"
              />

              <p class="text-red-400" *ngIf="errorControls.errorName">
                Nombre de plan requerido
              </p>
            </div>

            <div class="">
              <div for="code" class="block mb-2 text-sm font-semibold">
                <span class="text-red-500">*</span> Código de Plan
              </div>
              <input
                type="text"
                name="code"
                [(ngModel)]="plan.code"
                id="code"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="Código del Plan"
                required=""
                [disabled]="btnTitle == 'Actualizar Plan'"
              />

              <p class="text-red-400" *ngIf="errorControls.errorCode">
                Código de plan requerido
              </p>
            </div>
            <!-- <div *ngIf="false">
              <div class="font-semibold mb-1">Pagares</div>
              <label class="inline-flex items-center cursor-pointer">
                <input type="checkbox" value="" class="sr-only peer" />
                <div
                  class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"
                ></div>
                <span class="ms-3 text-sm font-medium text-gray-900"
                  >Permite pagaré electrónico</span
                >
              </label>
            </div> -->

            <div class="">




              <label for="code" class="block mb-2 text-sm font-semibold"
                >
                <span class="text-red-500">*</span>
                <span *ngIf="plan.version_change.length > 0"> Inicio</span>
                <span *ngIf="plan.version_change.length == 0"> Fecha de inicio</span>
                </label
              >

              <input
                [min]="today"
                type="date"
                name="code"
                [(ngModel)]="plan.params.init_date"
                id="code"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder=""
                required=""
                [disabled]="btnTitle == 'Actualizar Plan'"
              />
              <p class="text-red-400" *ngIf="errorControls.errorInitDate">
                Fecha de Inicio requerida
              </p>
            </div>
            <div class="">
              <label for="code" class="block mb-2 text-sm font-semibold"
                >
                <span *ngIf="plan.version_change.length > 0">Finalización</span>
                <span *ngIf="plan.version_change.length == 0"> Fecha de Finalización</span>
                </label>
              <input
                [min]="today"
                type="date"
                name="code"
                [(ngModel)]="plan.params.end_date"
                id="code"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder=""
                required=""
                (change)="validateDates()"
                [disabled]="!canEdit"
              />
              <p class="text-red-400" *ngIf="errorControls.errorEndDate">
                Fecha de Finalizacion debe ser valida
              </p>
            </div>
          </div>
          <!-- <div class="grid gap-4 lg:grid-cols-9 sm:gap-6 mt-4 mb-4">
            <div class="flex flex-col justify-center items-center">
              <div class="font-semibold text-center">
                Subir imagen
              </div>
              <div>
                <img class="w-[100px] h-[100px] p-1 rounded-full" src="https://flowbite.com/docs/images/people/profile-picture-5.jpg" alt="Bordered avatar">
              </div>
            </div>
            <div class="lg:col-span-8">
              <input
                  class="bg-gray-50 border text-xl border-gray-300 text-gray-400 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full mt-4"
                  id="file_input"
                  type="file"
               >
               <p class="mt-1 text-sm text-gray-500 " id="file_input_help">SVG, PNG, JPG or GIF (MAX. 800x400px).</p>

               <div class="flex justify-start gap-2">
                <button
                  (click)="validateFields()"
                  class="inline-flex  gap-2 items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-blue-500 rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-blue-200"
                >
                <img src="../../../../../assets/icons/upload.svg" alt="">
                 Subir Nueva Foto
                </button>
                <button
                (click)="validateFields()"
                class="inline-flex items-center px-3 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center  bg-white rounded-lg border focus:ring-4 focus:ring-primary-200 hover:bg-gray-200"
              >
                Borrar
              </button>
              </div>
            </div>
          </div> -->

          <div class="grid gap-4 lg:grid-cols-4 sm:gap-6">
            <div class="col-span-4">
              <div for="code" class="block mb-2 text-sm font-semibold">
                Descripción del plan
              </div>
              <textarea
                id="description"
                [(ngModel)]="plan.description"
                name="desc"
                rows="7"
                [disabled]="!canEdit"
                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                placeholder="Descripción del Plan"
              ></textarea>
              <p class="text-red-400" *ngIf="errorControls.errorDescription">
                Decripcion del plan requerida
              </p>
            </div>
            <div class="col-span-4" *ngIf="btnTitle !== 'Agregar Plan'">
              <label for="code" class="block mb-2 text-sm font-semibold"
                >Descripción de la Versión</label
              >
              <input
                type="text"
                name="code"
                [(ngModel)]="plan.version.name"
                id="code"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="Version del Plan"
                required=""
                disabled
              />
            </div>

            <div class="col-span-4">
              <div class="block mb-2 text-sm font-semibold">  <span class="text-red-500">*</span> Visualización</div>
              <div class="flex">

                <div class="flex items-center me-4">
                  <input
                    id="inline-radio1"
                    type="radio"
                    value="1"
                    [(ngModel)]="plan.visibility"
                    [disabled]="!canEdit"
                    name="inline-radio-group1"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    for="inline-radio1"
                    class="ms-2 text-sm font-medium text-gray-900"
                    >Público</label
                  >
                </div>
                <div class="flex items-center me-4">
                  <input
                    id="inline-2-radio1"
                    type="radio"
                    value="2"
                    [(ngModel)]="plan.visibility"
                    [disabled]="!canEdit"
                    name="inline-radio-group1"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    for="inline-2-radio1"
                    class="ms-2 text-sm font-medium text-gray-900"
                    >Privado</label
                  >
                </div>

                <p
                  class="text-red-400"
                  *ngIf="errorControls.errorVisualization"
                >
                  Selecione el tipo de visualizacion
                </p>
              </div>
            </div>

            <!-- <div class="sm:col-span-4 lg:col-span-2">
              <label for="countries" class="block mb-2 text-sm font-semibold"
                >Categoría</label
              >
              <select
              [(ngModel)]="filters.general.invoices.category"
                id="countries"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
              <option selected="">Selecione</option>
              <option value="RECARGAS">RECARGAS</option>
              </select>
            </div> -->
          </div>
        </div>
      </div>
      <h2>
        <button
          type="button"
          class="flex items-center justify-between w-full p-3 font-medium rtl:text-right border border-b-0 border-gray-200 hover:bg-gray-100 gap-3"
          (click)="mangeAccordion(1)"
          [ngClass]="{
            'text-blue-500': accordioncontroller[1] == true,
            'text-gray-500': accordioncontroller[1] == false
          }"
        >
          <span>Condiciones Generales del Plan</span>
          <svg
          data-accordion-icon
          class="w-3 h-3 rotate-180 shrink-0"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
          *ngIf="accordioncontroller[1] == false"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5 5 1 1 5"
          />
        </svg>
        <svg
          *ngIf="accordioncontroller[1] == true"
          data-accordion-icon
          class="w-3 h-3 shrink-0"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5 5 1 1 5"
          />
        </svg>
        </button>
      </h2>
      <div *ngIf="accordioncontroller[1] == true">
        <div class="py-3">
          <div class="grid gap-4 lg:grid-cols- sm:gap-6">
            <div class="lg:col-span-2">
              <div for="code" class="block mb-2 text-sm font-semibold">
                <span class="text-red-500">*</span> Cupo Mínimo
              </div>
              <input
                type="text"
                name="code"
                [(ngModel)]="plan.params.current_quota_min"
                [disabled]="!canEdit"
                id="code"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="Ingrese Cantidad"
                required=""
                (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
              />
              <p class="text-red-400" *ngIf="errorControls.errorQuotaMin">
                Indique el cupo mínimo
              </p>
            </div>
            <div class="lg:col-span-2">
              <div for="code" class="block mb-2 text-sm font-semibold">
                <span class="text-red-500">*</span> Cupo Máximo
              </div>
              <input
                type="text"
                name="code"
                [(ngModel)]="plan.params.current_quota_max"
                [disabled]="!canEdit"
                id="code"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="Cupo Máximo del Plan"
                required="Ingrese Cantidad"
                (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
              />
              <p class="text-red-400" *ngIf="errorControls.errorQuotaMax">
                Indique el cupo máximo
              </p>
            </div>
            <div class="lg:col-span-4">
              <div class="font-semibold mb-1">  <span class="text-red-500">*</span> Inicial</div>
              <label class="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  value=""
                  class="sr-only peer"
                  [(ngModel)]="plan.initial.status"
                  [disabled]="!canEdit"
                />
                <div
                  class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"
                ></div>
                <span class="ms-3 text-sm font-medium text-gray-400"
                  >Requiere Inicial</span
                >
              </label>
            </div>
          </div>

          <div
            class="grid gap-4 lg:grid-cols-3 sm:gap-6 mb-4"
            *ngIf="plan.initial.status"
          >
            <div class="lg:col-span-3">
              <div class="block mb-2 text-sm font-semibold">
                <span class="text-red-500">*</span> Forma de Cálculo de la Inicial
              </div>
              <div class="flex" *ngIf="plan.initial.status">
                <div class="flex items-center me-4" (click)="cleanInitialAmount()">
                  <input
                    id="inline-radio2"
                    type="radio"
                    value="1"
                    [(ngModel)]="plan.initial.type"
                    [disabled]="!canEdit"
                    name="inline-radio-group2"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    for="inline-radio2"
                    class="ms-2 text-sm font-medium text-gray-900"
                    >Porcentaje (%)</label
                  >
                </div>
                <div class="flex items-center me-4" (click)="cleanInitialAmount()">
                  <input
                    id="inline-2-radio2"
                    type="radio"
                    value="2"
                    [(ngModel)]="plan.initial.type"
                    name="inline-radio-group2"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    for="inline-2-radio2"
                    class="ms-2 text-sm font-medium text-gray-900"
                    >Monto (Valor Absoluto)</label
                  >
                </div>
                <p class="text-red-400" *ngIf="errorControls.errorInitialType">
                  Seleccione el tipo de cálculo
                </p>
              </div>
            </div>

            <div class="">
              <label
                *ngIf="plan.initial.type == '2'"
                for="code"
                class="block mb-2 text-sm font-medium text-gray-900"
                > <span class="text-red-500">*</span> Monto</label
              >
              <label
                *ngIf="plan.initial.type == '1'"
                for="code"
                class="block mb-2 text-sm font-medium text-gray-900"
                > <span class="text-red-500">*</span> Porcentage</label
              >
              <div class="flex items-center"  *ngIf="plan.initial.type">
                <div *ngIf="plan.initial.type == '2'" class="bg-gray-50 border border-gray-300 text-gray-900 px-4 py-2 rounded-l-lg"> USD</div>
                <div  *ngIf="plan.initial.type == '1'" class="bg-gray-50 border border-gray-300 text-gray-900 px-4 py-2 rounded-l-lg"> %</div>
                <input
                type="text"
                name="code"
                [(ngModel)]="plan.initial.amount"
                [disabled]="!canEdit"
                id="code"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-r-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="indique"
                required=""
                (ngModelChange)="validateMinAmount()"
                (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
              />
              </div>

              <p class="text-red-400" *ngIf="errorControls.errorInitialAmount">
                Indique la cantidad
              </p>
            </div>

            <div class="" *ngIf="plan.initial.type">
              <label for="code" class="block mb-2 text-sm font-semibold"
                >  <span class="text-red-500">*</span> Monto Mínimo de Compra</label
              >
              <input
                type="text"
                name="min_amount"
                [(ngModel)]="plan.params.min_amount"
                (change)="validateMinAmount()"

                [disabled]="!canEdit"
                id="min_amount"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="Monto"
                required=""
                (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
              />
              <p class="text-red-400" *ngIf="errorControls.errorMinAmountToPay">
                Cantidad inválida
              </p>
            </div>
          </div>

          <div class="grid gap-4 lg:grid-cols-3 sm:gap-6" *ngIf="plan.initial.type">
            <div class="">
              <label for="code" class="block mb-2 text-sm font-semibold"
                >  <span class="text-red-500">*</span> Monto Mínimo a Financiar</label
              >
              <input
                type="text"
                name="min_amount_base"
                id="min_amount_base"
                (change)="validateMinAmount()"

                [(ngModel)]="plan.params.min_amount_base"
                [disabled]="plan.initial.type == '1'"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="Monto"
                required=""
                (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
              />
              <p class="text-red-400" *ngIf="errorControls.errorMinAmountBase">
                Cantidad inválida
              </p>
            </div>

            <div class="">
              <label for="code" class="block mb-2 text-sm font-semibold"
                >  <span class="text-red-500">*</span> Monto Máximo a financiar</label
              >
              <input
                type="text"
                name="max_amount_base"
                [(ngModel)]="plan.params.max_amount_base"
                [disabled]="!canEdit"
                id="max_amount_base"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="Monto"
                required=""
                (change)="validateMaxAmount()"
                (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
              />
              <p class="text-red-400" *ngIf="errorControls.errorMaxAmountBase">
                Indique una cantidad válida
              </p>
            </div>


          </div>
        </div>
      </div>
      <h2>
        <button
          type="button"
          class="flex items-center justify-between w-full p-3 font-medium rtl:text-right border border-b-0 border-gray-200 hover:bg-gray-100 gap-3"
          (click)="mangeAccordion(2)"
          [ngClass]="{
            'text-blue-500': accordioncontroller[2] == true,
            'text-gray-500': accordioncontroller[2] == false
          }"
        >
          <span>Plazos</span>
          <svg
            *ngIf="accordioncontroller[2] == false"
            data-accordion-icon
            class="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
          <svg
            *ngIf="accordioncontroller[2] == true"
            data-accordion-icon
            class="w-3 h-3 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div *ngIf="accordioncontroller[2] == true">
        <div class="py-3">
          <div class="grid gap-4 sm:gap-6"
          [ngClass]="{
            'lg:grid-cols-2': plan.version_change.length > 0,
            'lg:grid-cols-3': plan.version_change.length == 0
          }"
          >

            <!-- <div
              class=""
              *ngIf="plan.payment_terms[0].name != ''"
              [ngClass]="{
                'lg:col-span-2': plan.version_change.length > 0,
                'lg:col-span-3': plan.version_change.length == 0
              }"
              >
              <div class="mb-4 font-semibold">Configuracion actual:</div>
              <div
                class="lg:col-span-4 bg-gray-50 p-4 rounded-lg mb-4"
                *ngFor="let item of plan.payment_terms"
              >
                {{ item.name }}
              </div>
            </div> -->
            <!-- <div class="">
              <label for="code" class="block mb-2 text-sm font-semibold"
                >Nombre</label
              >
              <input
                type="text"
                name="payment_terms_installments.name"
                id="payment_terms_installments"
                [(ngModel)]="terms.name"
                [disabled]="!canEdit"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="Nombre, o descripción corta"
                required=""
              />
              <p class="text-red-400" *ngIf="errorControls.errorPaymentTermsInstallment">

              </p>
            </div> -->
            <div class="">
              <label for="code" class="block mb-2 text-sm font-semibold"
                >  <span class="text-red-500">*</span> Cuotas</label
              >
              <input
                type="text"
                name="payment_terms_installments"
                id="payment_terms_installments"
                [(ngModel)]="terms.installments"
                [disabled]="!canEdit"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="indique las cuotas separadas por (,) ejemplo 1, 2 ,3"
                required=""
                (keypress)="($event.charCode >= 48 && $event.charCode < 58) || $event.charCode === 44"
              />
              <p
                class="text-red-400"
                *ngIf="errorControls.errorPaymentTermsInstallment"
              >
                Indique la cantidad
              </p>
            </div>

            <div class="">
              <label for="countries" class="block mb-2 text-sm font-semibold"
                > <span class="text-red-500">*</span> Frecuencia</label
              >
              <select
                [(ngModel)]="terms.frequency.type"
                [disabled]="!canEdit"
                id="countries"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option value="" selected disabled>Selecione</option>
                <option value="mes">Mes</option>
                <option value="semana">Semana</option>
                <option value="quincenal">Quincenal</option>
                <option value="dias">Días</option>
                <option value="dias_continuos">Personalizado</option>
              </select>
              <p class="text-red-400" *ngIf="errorControls.errorfrequencyType">
                Indique la frequencia
              </p>
            </div>

            <div class="" *ngIf="terms.frequency.type == 'dias_continuos'">
              <label for="code" class="block mb-2 text-sm font-semibold"
                >Cantidad de Días Continuos</label
              >
              <input
                [(ngModel)]="terms.frequency.number"
                [disabled]="!canEdit"
                type="number"
                name="payment_terms_installments"
                id="payment_terms_installments"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="Cantidad de dias"
                required=""
              />
              <p
                class="text-red-400"
                *ngIf="errorControls.errorInstallmentDays"
              >
                Indique la cantidad
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- <h2 id="accordion-collapse-heading-1">
        <button
          type="button"
          class="flex items-center justify-between w-full p-3 font-medium rtl:text-right border border-b-0 border-gray-200 hover:bg-gray-100 gap-3"
          (click)="mangeAccordion(3)"
          [ngClass]="{
            'text-blue-500': accordioncontroller[3] == true,
            'text-gray-500': accordioncontroller[3] == false
          }"
        >
          <span>Configuracion de Comisiones</span>
          <svg
            data-accordion-icon
            class="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2> -->
      <div *ngIf="accordioncontroller[3] == true">
        <div class="py-3"></div>
      </div>
      <h2 id="accordion-collapse-heading-1">
        <button
          type="button"
          class="flex items-center justify-between w-full p-3 font-medium rtl:text-right border border-b-0 border-gray-200 hover:bg-gray-100 gap-3"
          (click)="mangeAccordion(4)"
          [ngClass]="{
            'text-blue-500': accordioncontroller[4] == true,
            'text-gray-500': accordioncontroller[4] == false
          }"
        >
          <span>Filtros </span>
          <svg
          data-accordion-icon
          class="w-3 h-3 rotate-180 shrink-0"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
          *ngIf="accordioncontroller[4] == false"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5 5 1 1 5"
          />
        </svg>
        <svg
          *ngIf="accordioncontroller[4] == true"
          data-accordion-icon
          class="w-3 h-3 shrink-0"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5 5 1 1 5"
          />
        </svg>
        </button>
      </h2>
      <div *ngIf="accordioncontroller[4] == true">
        <div class="py-3">
          <div class="grid gap-4 lg:grid-cols-4 sm:gap-6">
            <div class="col-span-4">
              <div class="block mb-2 text-sm font-semibold">Tipo de Filtro</div>
              <div class="flex">
                <div class="flex items-center me-4">
                  <input
                    id="inline-radio3"
                    type="radio"
                    value="1"
                    [(ngModel)]="filterType"
                    [disabled]="!canEdit"

                    name="inline-radio-group3"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    for="inline-radio3"
                    class="ms-2 text-sm font-medium text-gray-900"
                    >Persona</label
                  >
                </div>
                <div class="flex items-center me-4">
                  <input
                    id="inline-2-radio3"
                    type="radio"
                    value="2"
                    [(ngModel)]="filterType"
                    [disabled]="!canEdit"
                    name="inline-radio-group3"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    for="inline-2-radio3"
                    class="ms-2 text-sm font-medium text-gray-900"
                    >Tienda</label
                  >
                </div>
                <p class="text-red-400" *ngIf="errorControls.errorFilterType">
                  Selecione el tipo de filtro
                </p>
              </div>
            </div>



            <div class="sm:col-span-4  block p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100" *ngIf="filterType == 1">
              <label for="countries" class="block mb-2 text-sm font-semibold"
                >Seleccione el tipo de persona</label
              >
              <select
                [(ngModel)]="filters.general.person.audience"
                [disabled]="!canEdit"
                name="audience"
                id="multiple_invoices_flag"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option selected="">Selecione</option>
                <option value="NATURAL">Natural</option>
                <option value="LEGAL">Jurídica</option>
              </select>
              <p class="text-red-400" *ngIf="errorControls.errorAudienceType">
                Selecione el tipo de audiencia
              </p>
            </div>



            <div class="col-span-4" *ngIf="filterType == 2">
              <div
                class="block p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100"
              >
                <div
                  class="block mb-2 text-lg font-semibold"
                  (click)="this.showListStores = false"
                >
                  Tiendas
                </div>

                  <div class="col-span-4  mb-4">
                    <div class="flex">
                      <div class="flex items-center me-4">
                        <input
                          id="inline-radio4"
                          type="radio"
                          value="1"
                          [(ngModel)]="filters.general.invoices.shop.type"
                          [disabled]="!canEdit"
                          name="inline-radio-group4"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                        />
                        <label
                          for="inline-radio4"
                          class="ms-2 text-sm  font-medium text-gray-900"
                          >Lista Blanca</label
                        >
                      </div>
                      <div class="flex items-center me-4">
                        <input
                          id="inline-2-radio4"
                          type="radio"
                          value="2"
                          [(ngModel)]="filters.general.invoices.shop.type"
                          [disabled]="!canEdit"
                          name="inline-radio-group4"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                        />
                        <label
                          for="inline-2-radio4"
                          class="ms-2 text-sm font-medium text-gray-900"
                          >Lista Negra</label
                        >
                      </div>
                      <p class="text-red-400" *ngIf="errorControls.errorShopType">
                        Selecione el tipo de lista de tiendas
                      </p>
                    </div>
                  </div>

                <input
                  (click)="this.showListStores = true"
                  [(ngModel)]="filterText"
                  (ngModelChange)="filteredItems()"
                  [disabled]="!canEdit"
                  type="text"
                  placeholder="Search..."
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                />
                <p class="text-red-400" *ngIf="errorControls.errorShopList">
                  debe selecionar al menos una tienda
                </p>
                <ul
                  class="border-gray-200 rounded-lg shadow overflow-y-auto max-h-60 z-20"
                  *ngIf="auxList.length > 0 && showListStores"
                >
                  <li
                    class="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100"
                    *ngFor="let item of auxList"
                    (click)="selectItem(item)"
                  >
                    <!-- <input
                        type="checkbox"
                        class="mr-2 rounded border-gray-300 focus:ring-0"
                      /> -->
                    <span>
                      {{ item.name }}
                    </span>
                  </li>
                </ul>

                <ul
                  class="border-gray-200 rounded-lg shadow overflow-y-auto max-h-60 z-20"
                  *ngIf="auxList.length == 0 && showListStores"
                >
                  <li
                    class="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100"
                    *ngFor="let item of shops"
                    (click)="selectItem(item)"
                  >
                    <!-- <input
                        type="checkbox"
                        class="mr-2 rounded border-gray-300 focus:ring-0"
                      /> -->
                    {{ item.name }}
                  </li>
                </ul>

                <ul
                  role="list"
                  class="divide-y divide-gray-200"
                  *ngIf="shopsForPayload.length > 0"
                  (click)="this.showListStores = false"
                >
                  <li class="py-3 sm:py-4" *ngFor="let item of shopsForPayload">
                    <div
                      class="flex items-center space-x-3 rtl:space-x-reverse"
                    >
                      <div class="flex-shrink-0">
                        <img
                          class="w-8 h-8 rounded"
                          src="../../../../../assets/icons/store-svgrepo-com.svg

                          "
                          alt="Neil image"
                        />
                      </div>
                      <div class="flex-1 min-w-0">
                        <p class="text-sm font-semibold text-gray-900 truncate">
                          {{ item.short_name }}
                        </p>
                        <p class="text-sm text-gray-500 truncate">
                          {{ item.description }}
                        </p>
                      </div>
                      <span
                        *ngIf="canEdit"
                        (click)="deletItem(item)"
                        class="cursor-pointer inline-flex items-center bg-gray-200 text-gray-800 text-xs font-medium px-2.5 py-0.5 rounded-full"
                      >
                        Eliminar
                      </span>
                    </div>
                  </li>
                </ul>
                <!--
                <button type="button" class="flex gap-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2  focus:outline-none">
                  <img src="../../../../../assets/icons/edit-user.svg" alt="">
                  Agregar
                </button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2 id="accordion-collapse-heading-2">
        <button
          type="button"
          class="flex items-center justify-between w-full p-3 font-medium rtl:text-right border border-b-0 border-gray-200 hover:bg-gray-100 gap-3"
          (click)="mangeAccordion(5)"
          [ngClass]="{
            'text-blue-500': accordioncontroller[5] == true,
            'text-gray-500': accordioncontroller[5] == false
          }"
        >
          <span>Códigos dinámicos</span>
          <svg
          data-accordion-icon
          class="w-3 h-3 rotate-180 shrink-0"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
          *ngIf="accordioncontroller[5] == false"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5 5 1 1 5"
          />
        </svg>
        <svg
          *ngIf="accordioncontroller[5] == true"
          data-accordion-icon
          class="w-3 h-3 shrink-0"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5 5 1 1 5"
          />
        </svg>
        </button>
      </h2>
      <div *ngIf="accordioncontroller[5] == true">
        <div class="py-3">
          <div class="grid gap-4 lg:grid-cols-4 sm:gap-6">


            <div class="col-span-4">
              <div
                class="block p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100"
              >
                <div
                  class="block mb-2 text-lg font-semibold"
                  (click)="this.showListCodes = false"
                >
                  Códigos
                </div>
                <div class="col-span-4 mb-4">
                  <div class="flex">
                    <div class="flex items-center me-4">
                      <input
                        id="inline-radio5"
                        type="radio"
                        value="1"
                        [(ngModel)]="filters.dinamic_code.person.type"
                        [disabled]="!canEdit"
                        name="inline-radio-group5"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                      />
                      <label
                        for="inline-radio5"
                        class="ms-2 text-sm font-medium text-gray-900"
                        >Lista Blanca</label
                      >
                    </div>
                    <div class="flex items-center me-4">
                      <input
                        id="inline-2-radio5"
                        type="radio"
                        value="2"
                        [(ngModel)]="filters.dinamic_code.person.type"
                        [disabled]="!canEdit"
                        name="inline-radio-group5"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                      />
                      <label
                        for="inline-2-radio5"
                        class="ms-2 text-sm font-medium text-gray-900"
                        >Lista Negra</label
                      >
                    </div>
                  </div>
                </div>

                <input
                  (click)="this.showListCodes = true"
                  [(ngModel)]="filterText2"
                  (ngModelChange)="filteredItems2()"
                  [disabled]="!canEdit"
                  type="text"
                  placeholder="Search..."
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                />

                <p class="text-red-400" *ngIf="errorControls.errorCodeList">
                  debe selecionar almenos un código
                </p>

                <ul
                  class="border-gray-200 rounded-lg shadow overflow-y-auto max-h-60 z-20"
                  *ngIf="auxList2.length > 0 && showListCodes"
                >
                  <li
                    class="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100"
                    *ngFor="let item of auxList2"
                    (click)="selectItem2(item)"
                  >
                    <!-- <input
                        type="checkbox"
                        class="mr-2 rounded border-gray-300 focus:ring-0"
                      /> -->
                    <span>
                      {{ item.code }}
                    </span>
                  </li>
                </ul>

                <ul
                  class="border-gray-200 rounded-lg shadow overflow-y-auto max-h-60 z-20"
                  *ngIf="auxList2.length == 0 && showListCodes"
                >
                  <li
                    class="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100"
                    *ngFor="let item of dynamicCodeList"
                    (click)="selectItem2(item)"
                  >
                    <!-- <input
                        type="checkbox"
                        class="mr-2 rounded border-gray-300 focus:ring-0"
                      /> -->
                    {{ item.code }}
                  </li>
                </ul>

                <ul
                  role="list"
                  class="divide-y divide-gray-200"
                  (click)="this.showListCodes = false"
                >
                  <li class="py-3 sm:py-4" *ngFor="let item of codeList">
                    <div class="block p-6 bg-white border border-gray-200 rounded-lg  hover:bg-gray-100">
                      <div
                      class="flex items-center space-x-3 rtl:space-x-reverse"
                    >
                      <div class="flex-1 min-w-0">
                        <p class="text-sm font-semibold text-gray-900 truncate">
                          {{ item.code }}
                        </p>
                        <p class="text-sm text-gray-500 truncate">
                          {{ item.description }}
                        </p>
                      </div>
                      <span
                      *ngIf="canEdit"
                        (click)="deletItem2(item)"
                        class="inline-flex items-center bg-gray-200 text-gray-800 text-xs font-medium px-2.5 py-0.5 rounded-full"
                      >
                        Eliminar
                      </span>
                    </div>
                    </div>

                  </li>
                </ul>

                <!-- <button type="button" class="flex gap-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2  focus:outline-none">
                  <img src="../../../../../assets/icons/edit-user.svg" alt="">
                  Agregar
                </button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2 id="accordion-collapse-heading-3">
        <button
          type="button"
          class="flex items-center justify-between w-full p-3 font-medium rtl:text-right border border-b-0 border-gray-200 hover:bg-gray-100 gap-3"
          (click)="mangeAccordion(6)"
          [ngClass]="{
            'text-blue-500': accordioncontroller[6] == true,
            'text-gray-500': accordioncontroller[6] == false
          }"
        >
          <span>Otros</span>
          <svg
          data-accordion-icon
          class="w-3 h-3 rotate-180 shrink-0"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
          *ngIf="accordioncontroller[6] == false"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5 5 1 1 5"
          />
        </svg>
        <svg
          *ngIf="accordioncontroller[6] == true"
          data-accordion-icon
          class="w-3 h-3 shrink-0"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5 5 1 1 5"
          />
        </svg>
        </button>
      </h2>
      <div *ngIf="accordioncontroller[6] == true">
        <div class="p-3">


          <div class="mb-4">
            <div class="font-semibold mb-1">Pagares</div>
            <!-- <div>enlazar variable</div> -->
            <label class="inline-flex items-center cursor-pointer">
              <input type="checkbox" value=""   [(ngModel)]="electronicBill" class="sr-only peer"  />
              <div
                class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"
              ></div>
              <span class="ms-3 text-sm font-medium text-gray-400">
                Permite Pagaré Electrónico
              </span>
            </label>
          </div>

          <div class="mb-4">
            <div class="font-semibold mb-1">
              Cuotas fines de semana y feriados
            </div>
            <label class="inline-flex items-center cursor-pointer">
              <input type="checkbox" value=""   [(ngModel)]="weekendAmounts" class="sr-only peer" />
              <div
                class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"
              ></div>
              <span class="ms-3 text-sm font-medium text-gray-400"
                >Diferir cuotas de fines de semana</span
              >
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="flex items-center  mt-4" *ngIf="canEdit">
      <input
        id="default-checkbox"
        type="checkbox"
        [(ngModel)]="formTerms"
        [disabled]="!canEdit"
        value=""
        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
      />
      <label
        for="default-checkbox"
        class="ms-2 text-sm font-medium text-gray-900"
        >Lorem, ipsum dolor sit amet consectetur adipisicing elit.</label
      >


    </div>
    <p class="text-red-400" *ngIf="errorControls.errorFormTerms">
      Campo requerido
    </p>
    <br>

    <button
      *ngIf="canEdit"
      [disabled]="!canEdit"
      (click)="validateFields()"
      type="button"
      class="mt-4 flex gap-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none"
    >
      {{ btnTitle }}
    </button>
    <p class="text-red-400" *ngIf="globalError">
      Revise el fromulario y corrija los errores resaltados
    </p>

    <div class="flex justify-center items-center w-full" *ngIf="isLoading">
      <img src="../../../../../assets/public/images/loading.gif" alt="">
    </div>
  </div>

  <div
    class="version-control lg:col-span-3 border-l"
    *ngIf="plan.version_change.length > 0"
  >
    <div
      class="flex items-center justify-between w-full p-3 font-medium rtl:text-right border-b border-gray-200 hover:bg-gray-100 gap-3"
    >
      Historial




<ul class="hidden text-sm font-medium text-center text-gray-500 rounded-lg  sm:flex ">
  <li class="w-full focus-within:z-10">
      <div  class="inline-block w-full p-1 bg-white border border-gray-400  hover:text-gray-700 rounded-s-lg hover:bg-gray-50 focus:ring-4 focus:ring-blue-300 focus:outline-none ">XXXX</div>
  </li>
  <li class="w-full focus-within:z-10">
      <div
     class="inline-block w-full p-1 bg-white border border-gray-400  hover:text-gray-700 hover:bg-gray-50 focus:ring-4 focus:ring-blue-300 focus:outline-none text-blue-600 ">Historial</div>
  </li>
  <li class="w-full focus-within:z-10">
      <div  class="inline-block w-full p-1 bg-white border border-gray-400  hover:text-gray-700 rounded-e-lg hover:bg-gray-50 focus:ring-4 focus:ring-blue-300 focus:outline-none ">XXXX</div>
  </li>

</ul>

    </div>

    <div class="p-4 ">
      <span class="text-sm text-gray-400">Timeline</span>
      <ol class="relative border-s border-gray-200" *ngIf="!configHistoryChange">
        <li class="mb-10 ms-6" *ngFor="let item of plan.version_change; let i=index;">
          <h3  class="mt-4 mb-2">
          <span class="font-semibold">Versión {{item.version.internal_code}}</span> &nbsp;  <span *ngIf="i == 0" class=" bg-blue-100 rounded-lg text-blue-600 text-sm px-3 py-1"> Último</span>
          </h3>
          <span
            class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white"
          >
            <svg
              class="w-2.5 h-2.5 text-blue-800"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"
              />
            </svg>
          </span>

          <time
            class="block mb-1 mt-1 text-sm font-normal leading-none text-gray-400">Usuario actualizó {{item.version.date_init_version |date}}</time>

            <div class="text-gray-400">
              Se realizaron cambios en: <span *ngFor="let element of item.chaneges"> {{element.field}}, </span>
            </div>
          <div (click)="showHistorydetails(item)"
          class="
          flex gap-2
          justify-center
          items-center
           text-gray-900 text-sm
           mt-[10px] py-[5px] px-[30px]"
           style="border: solid 1px #c6c7c7; border-radius: 10px;
      "
      [ngClass]="{'w-[160px]': item.showSubItems == false, 'w-[200px]': item.showSubItems == true}"
      >
            <img src="../../../../../assets/icons/zip.svg" alt="">

            <span *ngIf="!item.showSubItems">Ver cambios</span>
            <span *ngIf="item.showSubItems">Ocultar Cambios</span>
          </div>
          <div *ngIf="item.showSubItems">
            <ul class="text-base font-normal text-gray-500 ml-4" *ngFor="let element of item.chaneges">
              <li *ngIf="element.field != 'payment_terms'">
                <strong>Campo actualizado: </strong>{{element.field}} <br>
                <strong>Valor nuevo: </strong>{{element.newValue}} <br>
                <strong> Valor anterior: </strong>{{element.oldValue}}
              </li>

              <li *ngIf="element.field == 'payment_terms'">
                <strong>Campo actualizado: </strong>{{element.field}} <br>
                 configuracion de cuotas modificada
              </li>
            </ul>
          </div>


        </li>
      </ol>
    </div>
  </div>
</div>
